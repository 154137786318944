div.swal2-container {
    z-index: 1300 !important;
}

div.swal2-styled.swal2-confirm {
    background-color: 'blue ' !important;
}

.pac-container{
    z-index: 999999;
}


/* 
.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
    max-height: fit-content !important;
}

.MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: revert;
    white-space: inherit !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.MuiDataGrid-root .MuiDataGrid-cellContent {
    overflow: initial;
    text-overflow: revert;
} */


.App-map {
    height: 100%;
    width: 100%;
    position: absolute !important;
}